body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  position: relative;
}

p:last-child {
  margin-bottom: 0;
}

@media print {
  body * {
    visibility: hidden;
  }
  .printable,
  .printable * {
    visibility: visible;
    font-size: 15pt;
    margin: 5mm 5mm 5mm 5mm;
  }

  .printable {
    position: absolute;
    left: 0;
    top: 0;
  }
}
