#dropdownMenuButton {
  background-color: $white;
  border: 1px solid $white;
  border-radius: 9000px !important;
  color: #000;

  &:hover,
  &:focus {
    color: #fff;
    border: 1px solid $primary;
    background-color: $primary;
  }
}

.dropdown-toggle::after {
  display: none; // hide default bootstrap dropdown caret
}

.dropdown-menu {
  background-color: $gray-800;
}

.dropdown-item {
  color: $secondary;

  &:hover {
    background-color: $gray-700;
    color: $secondary;
  }
}

.account-box {
  background-color: $white;
  color: $black !important;
  padding: 6px 12px;
  border: 2px solid $white;
  border-radius: 9000px !important;
}

// Navbar
.navbar-nav {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  padding: 2px !important;
  border: 2px solid $primary;
}

.nav-link {
  padding: 8px 24px !important;
  width: 102px;
  text-align: center;
}

.nav-pills {
  background-color: #000;
  font-family: 'Inter', sans-serif !important;
  font-weight: 500;
}
