.seedphrase {
  background-color: rgba(#ddd, 0.1);
  backdrop-filter: blur(10px);
  padding: 8px;
  border-radius: 0.5rem;
  font-family: "Space Mono", monospace !important;
}

.seedphrase-item-nr {
  width: 36px;
  white-space: nowrap;
}

.blurred {
  filter: blur(0.4rem);
}

.copy,
.phrase-toggle {
  font-size: 0.75rem;

  &:hover {
    /* background-color: $gray-800; */
    color: $cyan;
    cursor: pointer;
  }
}
