.cards {
  display: grid;
  grid-column-gap: 22px;
  grid-row-gap: 22px;
  max-width: 1040px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }
}

.card {
  background-color: rgba(#000,0.8);
  backdrop-filter: blur(10px);
  border: 1px solid #222;
  border-radius: 1rem;
}

.card-body {
  padding: 2rem;
  
  @media (min-width: 576px) {
    padding: 2rem 3rem;
  }
}

.card-footer {
  padding: 1.5rem 2rem;
  font-size: 0.75rem;
  color: $secondary-darker;
}

.back-arrow {
  position: absolute;
  left: 0;
  top: 0.6rem;
  color: $secondary-darker;
  cursor: pointer;

  &:hover {
    color: $secondary;
  }

  @media (min-width: 576px) {
    top: 1.2rem;
  }
}

.divider {
  color: $secondary;

  .border-top {
    border-top-color: $gray-800 !important;
  }
}

.image-box {
  max-height: 3rem;
  height: auto;
  max-width: 90%;
  width: auto;

  .nft {
    border: 1px solid $gray-700;
  }
}

.card-image {
  display: inline-block;
  width: 144px;
  height: 144px;
  background: no-repeat center center;
  background-size: 144px;

  &--create {
    background-image: url('../images/icon_kusama_create.svg');
  }

  &--share {
    background-image: url('../images/icon_kusama_share.svg');
  }

  &--claim {
    background-image: url('../images/icon_kusama_claim.svg');
  }
}

.card-text {
  &--explanation {
    max-width: 256px;
    @media (min-width: 992px) {
      padding: 0 20px;
    }
  }
}
