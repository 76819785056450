@import url(/src/fonts/unbounded/font-face.css);
@import url(/src/fonts/inter/font-face.css);
@import url(/src/fonts/spacemono/font-face.css);

$font-body: 'Inter', sans-serif !important;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

body {
  font-family: $font-body;
  color: $gray;
  font-size: 1.125rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Unbounded', sans-serif !important;
  font-weight: $font-weight-bold;
  letter-spacing: -0.04em;
}

.card-header-title {
  font-family: 'Unbounded', sans-serif !important;
  font-weight: $font-weight-bold;
  font-size: 1.75rem;
  color: $secondary-lighter;

  @media (min-width: 576px) {
    font-size: 2.25rem;
  }
}

.about-heading {
  font-size: 1.75rem;

  @media (min-width: 576px) {
    font-size: 2.5rem;
  }

  @media (min-width: 768px) {
    font-size: 3.5rem;
  }
}

a {
  color: $primary;

  &:hover {
    color: $primary;
    text-decoration: none;
  }
}

.text-sideline {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-family: "Space Mono", monospace !important;
  font-size: 0.875rem;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #333;
  }
}
