/* Standard Background */

/*
body {
  background-image: url('../images/Gifts_Kusama_BG.svg');
  background-repeat: repeat;
  background-position: center;
  background-color: #000;
}
*/

/* Campaign Background */

body {
  background-image: url('../images/kusama-nft-bg.jpg');
  background-repeat: no-repeat;
  background-position: top;
  background-color: #000;
  background-size: cover;
}

/* Campaign Background old */

/* body {
  background-image: url('../images/nft_bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#root {
  background: radial-gradient(transparent, rgba(0,0,0,0.4));
} */
