// Colors
// ---------

$primary: #e6007a;
$primary-darker: #c40061;
$primary-lighter: #ff4da0;
$primary-highlight: #ffe6f4;

$secondary: #ddd;
$secondary-lighter: #eee;
$secondary-darker: #6d6d6d;

$black: #000;
$gray: #ddd;
$white: #fff;

$cyan: #00ffe1;

// Radii
$border-radius: 0.5rem;
