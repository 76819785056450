footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #fff;
  font-size: 0.75rem;
  padding: 16px 48px;

  @media (min-width: 0px) {
    font-size: 0.875rem;
  }

  @media (min-width: 768px) {
    font-size: 0.75rem;
  }

  a {
    color: #fff;
  }
}

.bg-solid {
  background: black;
}

#root {
  // bottom paddind is needed to prevent the overlap with the card.
  padding-bottom: 6rem;
  @media (min-width: 768px) {
    padding-bottom: 3rem;
  }
}
